import { useState } from 'react';
import Color from '../../utils/Color';

export default function AccountValidationEchecPage() {


    return (<>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ flex: 1, display: 'flex', margin: 15, maxWidth: 500, flexDirection: 'column', alignContent: 'center', justifyContent: 'center', marginBottom: 25 }}>
                <center>
                    <img src="/assets/logo_sup.png" style={{ height: 50, width: 50 }} alt="Logo" />
                </center>
                <p style={{ fontSize: 25, color: Color.PINK, fontWeight: 500 }}>Salut !</p>
                <p style={{ color: Color.DARK_RED, fontWeight: 900 }}>Nous sommes désolés, mais la validation de ton compte a échoué. Merci de réessayer plus tard ou de contacter le support avec les identifiants que tu as fournis lors de ton inscription.</p>            </div>
            <footer>
                <strong style={{ fontWeight: 600 }}>© Supporters 2024</strong>
                <div>
                    <a href="#">Mentions légales</a>
                    <a href="#">Politique de confidentialité</a>
                </div>
            </footer>
        </div>
    </>
    )
}