import { useState } from 'react';
import '../../../Login.css';
import Color from "../../utils/Color";

export default function LandingPage() {

    const [showError, setShowError] = useState(false);

    return (<>
        {showError && (
            <div className='error-modal' onClick={() => setShowError(false)}>
                <div>
                    <h1>Bientôt disponible !</h1>
                    <h2>L'application web n'est pour le moment pas disponible, mais tu peux nous retrouver sur playstore & appstore en attendant</h2>
                </div>
            </div>
        )}
        <div className="container">
            <div className="content">
                <div className="left-pane">
                    <img src="/assets/mockup.png" className="mockup" alt="" />
                </div>
                <div className="right-pane">
                    <div className="content-form">
                        <form method="POST" className="login-form">
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                                <img src="/assets/logo_sup.png" className="logo" alt="" />
                            </div>
                            <div className='title' style={{ width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ width: '100%', background: 'rgba(78,78,78, 0.045)', height: 2 }}></div>
                                </div>
                                <div style={{ width: '100%' }}>Déjà inscrit</div>
                                <div style={{ width: '100%' }}>
                                    <div style={{ width: '100%', background: 'rgba(78,78,78, 0.045)', height: 2 }}></div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Identifiant</label>
                                <input type="email" id="email" name="email" placeholder="john.doe@gmail.com" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Mot de passe</label>
                                <input type="password" id="password" name="password" placeholder="••••••••" />
                            </div>
                            <button type="button" onClick={() => setShowError(true)} className="login-button">Se connecter</button>
                            <div className="forgot-password">
                                <a href="#">Mot de passe oublié</a>
                            </div>
                            <div className='title' style={{ width: '100%', marginTop: 25 }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ width: '100%', background: 'rgba(78,78,78, 0.045)', height: 2 }}></div>
                                </div>
                                <div style={{ width: '100%' }}>Pas de compte ?</div>
                                <div style={{ width: '100%' }}>
                                    <div style={{ width: '100%', background: 'rgba(78,78,78, 0.045)', height: 2 }}></div>
                                </div>
                            </div>
                            <div className="register-prompt" style={{ marginTop: 5 }}>
                                <button onClick={() => setShowError(true)} type="button" className="register-button">Me créer un compte</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer>
                <strong style={{ fontWeight: 600 }}>© Supporters 2024</strong>
                <div>
                    <a href="#">Mentions légales</a>
                    <a href="#">Politique de confidentialité</a>
                </div>
            </footer>
        </div></>
    )
}